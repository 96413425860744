import * as React from 'react';

export default function Directions({ className, viewBox }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      className={className}
      viewBox={viewBox || '0 0 16 16'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4729 7.52703L8.47289 1.52703C8.21289 1.26703 7.79289 1.26703 7.53289 1.52703L1.53289 7.52703C1.27289 7.78703 1.27289 8.20703 1.53289 8.46703L7.53289 14.467C7.79289 14.727 8.21289 14.727 8.47289 14.467L14.4729 8.46703C14.7329 8.2137 14.7329 7.7937 14.4729 7.52703ZM9.33289 9.66703V8.00036H6.66622V9.3337C6.66622 9.70036 6.36622 10.0004 5.99956 10.0004C5.63289 10.0004 5.33289 9.70036 5.33289 9.3337V7.3337C5.33289 6.96703 5.63289 6.66703 5.99956 6.66703H9.33289V5.00036L11.4329 7.10036C11.5662 7.2337 11.5662 7.44036 11.4329 7.5737L9.33289 9.66703Z"
        fill="currentColor"
      />
    </svg>
  );
}

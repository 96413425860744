export const MAX_GETAWAY_DISTANCE = 1000000;

export const QUATRO_LEADER_IDS =
  process.env.APP_STAGE === 'production'
    ? ['cc49358e-85c5-4ba8-a96a-1d9249116edc', '40c49517-87fa-4e4e-8e3f-303b343cead9'] // production IDs
    : ['1b8586f5-a1f9-4456-873c-59f6032da9d2', 'd2938e9f-129a-4032-924e-61c96e8c8601']; // staging IDs

export const DEFAULT_GEOGRAPHY = {
  type: 'Point' as const,
  coordinates: [0, 0],
};

export const DEFAULT_DISTANCE = 50; // in miles
export const MAX_DISTANCE = 1000; // in miles

import { useState } from 'react';
import { toast } from 'react-hot-toast';
import api from 'services/client/api';
import { Button } from 'components/Quatro/Button';
import classNames from 'styles/utils/classNames';
import NewsletterStyled from './styles';

const NewsletterSection = ({ className }: { className?: string }) => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await api.post('v1/newsletter/quatro-subscribe', {
        payload: { email },
      });

      setEmail('');
      toast.success('Successfully subscribed to newsletter!');
    } catch (error) {
      console.error('Failed to subscribe:', error);
      toast.error('Failed to subscribe. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <NewsletterStyled className={classNames('flex min-h-[25rem]', className)}>
      <div className="container mx-auto flex flex-1 flex-col items-center justify-center py-20 text-center">
        <h2 className="typography-informative-heading-desktop text-color-text-lightmode-invert dark:text-color-text-darkmode-invert">
          Subscribe to our Newsletter
        </h2>
        <form onSubmit={handleSubmit} className="relative mt-8 h-11 w-full max-w-[25rem]">
          <input
            className="typography-product-body h-full w-full rounded-l border-0 pr-32 outline-0 focus-within:!border-0 focus-within:!ring-offset-0 focus:!outline-0 focus:!ring-0 focus-visible:!shadow-none focus-visible:!ring-offset-0"
            placeholder="Your email"
            type="email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={isLoading}
          />
          <Button
            type="submit"
            className="absolute right-[3px] top-[3.5px] max-w-28"
            disabled={isLoading}
          >
            {isLoading ? 'Subscribing...' : 'Subscribe'}
          </Button>
        </form>
      </div>
    </NewsletterStyled>
  );
};

export default NewsletterSection;

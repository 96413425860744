import { BecomeCoachSectionStyled } from 'screens/QuatroHome/styles';
import { ButtonLink } from 'components/Quatro/Button';

const BecomeSection = () => {
  return (
    <BecomeCoachSectionStyled className="relative mt-28 text-color-text-lightmode-invert dark:text-color-text-darkmode-invert">
      <div className="quatro-container mx-auto flex gap-4 py-20">
        <div className="relative z-10">
          <h2 className="typography-informative-heading-desktop ">Become a Quatro Coach</h2>
          <p className="typography-informative-subheading-medium mt-ds-xl max-w-[20rem] ">
            Join us and take the first step towards becoming a certified pickleball coach with
            Quatro Pickleball.
          </p>
          <ButtonLink className="mt-6" href="/quatro/about" size="lg" variant="secondary-invert">
            Join Us
          </ButtonLink>
        </div>
      </div>
      <div className="container absolute inset-0 mx-auto px-4 sm:px-0">
        <img
          src="/images/quatro/home/yellow-balls.png"
          alt="yellow-balls"
          className="absolute bottom-0 right-0 top-0 h-full max-md:hidden"
        />
      </div>
    </BecomeCoachSectionStyled>
  );
};

export default BecomeSection;

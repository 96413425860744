import { format } from 'date-fns';
import Image from 'next/image';
import { getLessonPageUrl } from 'constants/pages';
import { EMPTY_AVATAR_SRC } from 'constants/user';
import { nameToLastInitials } from 'utils/shared/name/nameToLastInitials';
import { getProfileImageUrlOrPlaceholder } from 'utils/shared/user/getProfileImageUrlOrPlaceholder';
import CoachBadge from 'svg/CoachBadge';
import Directions from 'svg/Directions';
import Location from 'svg/Location';
import { ButtonLink } from 'components/Quatro/Button';
import { ClinicCardProps } from './types';

const ClinicCard: React.FC<ClinicCardProps> = ({ lesson }) => {
  const startDateTime = lesson?.startDateTime;
  const lessonDate = startDateTime && format(new Date(startDateTime), 'eeee MMM. d');
  const lessonStartTime = startDateTime && format(new Date(startDateTime), 'p');
  const lessonEndTime = lesson?.endDateTime && format(new Date(lesson.endDateTime), 'p');
  const participantLimit = lesson?.participantLimit || 0;
  const participantCount = lesson?.participants.length;
  const spotsLeft = participantLimit - participantCount;

  const emptyParticipantSlots = new Array(Math.max(0, participantLimit - participantCount)).fill(
    null,
  );

  const renderLocation = () => (
    <div className="inline-flex gap-1 text-color-text-lightmode-tertiary dark:text-color-text-darkmode-tertiary">
      <Location className="h-4 w-4 shrink-0" />
      <span className="typography-product-caption">
        {lesson?.userCustomCourt?.fullAddress || lesson?.venue?.addressString}
      </span>
    </div>
  );

  const renderParticipants = () => (
    <div className="mt-4 flex flex-wrap gap-4">
      {lesson?.participants.map((participant) => (
        <Participant
          key={participant.id}
          name={nameToLastInitials(participant.userProfile?.fullName || 'Player')}
          imagePath={getProfileImageUrlOrPlaceholder({
            path: participant.userProfile?.profileImagePath || '',
          })}
          isFilled={true}
        />
      ))}
      {emptyParticipantSlots.map((_, index) => (
        <Participant key={index} name="Open" imagePath={EMPTY_AVATAR_SRC} isFilled={false} />
      ))}
    </div>
  );

  return (
    <div className="flex gap-ds-2xl border  border-color-border-card-lightmode bg-color-bg-lightmode-primary p-ds-2xl shadow-md dark:border-color-border-card-darkmode dark:bg-color-bg-darkmode-primary max-sm:flex-col max-sm:gap-ds-xl max-sm:p-ds-xl">
      {/* Location and Directions */}
      <div className="flex min-w-0 flex-1 flex-col">
        <span className="typography-product-caption text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
          {lessonDate} <span className="text-color-brand-primary">&bull;</span> {lessonStartTime} -{' '}
          {lessonEndTime}
        </span>

        {/* Lesson Title */}
        <span className="typography-product-heading mt-4 w-full  text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
          {lesson?.title}
        </span>

        <div className="mt-2 flex items-center justify-between gap-1">
          {renderLocation()}
          <a
            target="_blank"
            href={`https://maps.google.com?q=${
              lesson?.userCustomCourt?.fullAddress || lesson?.venue?.addressString
            }`}
            className="inline-flex items-center gap-1"
          >
            <Directions className="h-4 w-4 shrink-0" />
            <span className="typography-product-button-label-medium">Directions</span>
          </a>
        </div>

        <div className="mt-10 flex items-center gap-2">
          <span className="typography-product-subheading">Coach</span>
          <div className="flex items-center gap-1">
            <img
              className="h-8 w-8 rounded-full"
              src={lesson.ownerProfile?.profileImageProviderUrl || EMPTY_AVATAR_SRC}
              alt="coach-image"
            />
            <span className="typography-product-element-label">
              {lesson.ownerProfile?.fullName}
            </span>
            <CoachBadge className="h-5 w-5 text-color-brand-primary" />
          </div>
        </div>

        <div className="mb-10 mt-12">
          <div className="inline-flex items-center gap-4">
            <span className="typography-product-subheading">
              Players going {lesson?.participants.length || '0'}/{lesson?.participantLimit}
            </span>
            <span className="typography-product-caption text-color-text-lightmode-tertiary dark:text-color-bg-darkmode-tertiary">
              {spotsLeft} spots left
            </span>
          </div>

          {renderParticipants()}
        </div>
        <ButtonLink
          href={getLessonPageUrl(lesson.id)}
          variant="secondary"
          size="md"
          block
          className="mt-auto"
        >
          View Details
        </ButtonLink>
      </div>
    </div>
  );
};

export default ClinicCard;

const Participant: React.FC<{ name: string; imagePath: string; isFilled: boolean }> = ({
  name,
  imagePath,
  isFilled,
}) => (
  <div className="flex flex-col items-center gap-2">
    <div className="relative h-8 w-8 rounded">
      <Image fill src={imagePath} alt="avatar" className={!isFilled ? 'grayscale' : ''} />
    </div>
    <span className="typography-product-tabbar-mobile">{name}</span>
  </div>
);

import styled from 'styled-components';

export const BecomeCoachSectionStyled = styled.section`
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%), #74c3d5;
`;

export const DesignedGradientStyled = styled.div`
  background: linear-gradient(0deg, rgba(47, 15, 4, 0.8) 0%, rgba(47, 15, 4, 0.8) 100%);
  background-color: lightgray; /* Fallback color */
  background-position: 50%;
  background-size: cover;
  position: absolute;
  z-index: 2;
  inset: 0;
  opacity: 0.85;
`;

export const HeroGradientStyled = styled.div`
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.64) 60.77%,
    rgba(0, 0, 0, 0.8) 100%
  );
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  inset: 0;
  z-index: 2;
`;
